<template>
  <v-dialog v-model="dialog" @click:outside="closedDialog">
    <v-card>
      <v-card-title>
        Formatted View
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <vue-json-pretty :data="json" :showDoubleQuotes="false"></vue-json-pretty>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="closedDialog" color="primary">Close</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: "TableViewJsonSelectedRowColumnDialog",

  components: {VueJsonPretty},

  props: {
    json: {
      required: true,
    },

    dialog: {
      required: true,
      type: Boolean
    }
  },

  methods: {
    closedDialog() {
      this.$emit("TableViewSelectedRowColumnDialogClosed", true)
    }
  }
}
</script>

<style scoped>

</style>
